import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar } from 'recharts';
import './App.css'; // Ensure you have the required CSS

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

const mockData = {
  overallExperience: [
    { name: 'Excellent', value: 45 },
    { name: 'Good', value: 30 },
    { name: 'Average', value: 15 },
    { name: 'Poor', value: 7 },
    { name: 'Very Poor', value: 3 },
  ],
  relevanceAndQuality: [
    { name: 'Topic Relevance', score: 4.5 },
    { name: 'Topic Quality', score: 4.3 },
    { name: 'Learning Experience', score: 4.6 },
    { name: 'Event Organization', score: 4.2 },
    { name: 'Program Satisfaction', score: 4.4 },
  ],
  engagementAndSpeakers: [
    { name: 'Event Engagement', score: 4.7 },
    { name: 'Speaker Quality', score: 4.8 },
  ],
  venueRating: [
    { name: 'Excellent', value: 40 },
    { name: 'Good', value: 35 },
    { name: 'Average', value: 15 },
    { name: 'Poor', value: 8 },
    { name: 'Very Poor', value: 2 },
  ],
  futureTopic: [
    { name: 'UX Research', value: 30 },
    { name: 'UI Design', value: 25 },
    { name: 'UX Writing', value: 20 },
    { name: 'Interaction Design', value: 15 },
    { name: 'Design Systems', value: 10 },
  ],
};

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState('overall');

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">CebUXD Event Feedback Summary</h1>
      <div className="tabs">
        <div className="tabs-list">
          <button onClick={() => setActiveTab('overall')}>Overall Experience</button>
          <button onClick={() => setActiveTab('relevance')}>Relevance & Quality</button>
          <button onClick={() => setActiveTab('engagement')}>Engagement & Speakers</button>
          <button onClick={() => setActiveTab('venue')}>Venue & Future Topics</button>
        </div>
        <div className="tabs-content">
          {activeTab === 'overall' && (
            <div className="card">
              <div className="card-header">Overall Experience</div>
              <div className="card-content">
                <ResponsiveContainer width="100%" height={300}>
                  <PieChart>
                    <Pie
                      data={mockData.overallExperience}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                      label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                    >
                      {mockData.overallExperience.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>
          )}
          {activeTab === 'relevance' && (
            <div className="card">
              <div className="card-header">Relevance & Quality Ratings</div>
              <div className="card-content">
                <ResponsiveContainer width="100%" height={300}>
                  <RadarChart data={mockData.relevanceAndQuality}>
                    <PolarGrid />
                    <PolarAngleAxis dataKey="name" />
                    <PolarRadiusAxis angle={30} domain={[0, 5]} />
                    <Radar name="Score" dataKey="score" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                    <Tooltip />
                  </RadarChart>
                </ResponsiveContainer>
              </div>
            </div>
          )}
          {activeTab === 'engagement' && (
            <div className="card">
              <div className="card-header">Engagement & Speakers</div>
              <div className="card-content">
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={mockData.engagementAndSpeakers}>
                    <XAxis dataKey="name" />
                    <YAxis domain={[0, 5]} />
                    <Tooltip />
                    <Bar dataKey="score" fill="#82ca9d" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          )}
          {activeTab === 'venue' && (
            <div>
              <div className="card">
                <div className="card-header">Venue Rating</div>
                <div className="card-content">
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={mockData.venueRating}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                        label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                      >
                        {mockData.venueRating.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div className="card mt-4">
                <div className="card-header">Preferred Future Topics</div>
                <div className="card-content">
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={mockData.futureTopic}>
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="value" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// Attach the React component to a global variable to be accessible in the HTML embed script
window.ReactDashboard = Dashboard;

export default Dashboard;